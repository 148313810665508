/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'gatsby';
import { Layout, SEO } from '@/components';

export const Head = ({ location }) => {
  const title = 'March newsletter';
  const description = 'Njano is a cloud-based flexible office management solution designed to manage workplaces';
  return <SEO title={title} description={description} location={location} />;
};

const ArticlePage = () => (
  <Layout dark logo>
    <div className="md:max-w-5xl container w-full px-16 pt-20 mx-auto bg-white shadow-lg">
      <div className="md:px-6 w-full px-4 text-xl leading-normal text-gray-800">
        <div className="font-content">
          <span className="text-primary md:text-sm space-y-8 text-base font-bold">
            <Link to="/" className="bg-secondary md:text-sm px-6 py-2 text-base font-bold text-white no-underline">
              &#8592; BACK TO HOME
            </Link>
            <h1 className="font-heading md:text-5xl pt-6 pb-2 text-3xl font-bold text-gray-900 break-normal">
              March Newsletter
            </h1>
          </span>
        </div>
        <p className="py-6">
          With the end of winter, we hope March has been your great month to “spring” into effective and impactful
          actions.
        </p>
        <p className="py-6">
          This month’s newsletter is dedicated to businesses running incubation programs. In fact, the number of
          accelerators and incubators went up five times between 2009 and 2018 and the trend is still ongoing.
        </p>
        <p className="py-6">
          And with this wide and fast expansion, diverse operational needs have surfaced. As a way to solve them, Njano
          has widened its target by adding incubators to its frustration-free Management solution.
        </p>
        <p className="py-6">
          Hence, here’s the main sections Njano designed and developed to make incubators&apos; experience easier!
        </p>
        <p className="py-6">
          Hence, here’s the main sections Njano designed and developed to make incubators&apos; experience easier!
        </p>
        <p className="py-6">
          <h4 className="font-heading mb-4 font-bold">Easy One-on-one Booking System</h4>
          The complexity and cost of booking platforms make the meetings scheduling difficult. Njano offers within its
          solution a section dedicated to this need. It includes easy availability setting and booking, instant
          notification system along with a virtual video-conference for the meeting.
        </p>
        <p className="py-6">
          <h4 className="font-heading mb-4 font-bold">Smooth Interviews Management</h4>
          Incubators find it hard to interview their potential beneficiaries by jumping from one tool to another:
          between reuniting the jury, setting a meeting, evaluating the candidate and deliberating.
        </p>
        <p className="py-6">
          Sensing these hardships, the Interviews section comes as a one stop shop feature that covers all of the tasks
          mentioned above in a user-friendly way.
        </p>
        <p className="py-6">
          <h4 className="font-heading mb-4 font-bold">Highly Customizable Assignments</h4>
          Njano sensed the importance of this section for incubators which led us to put great efforts to make it highly
          customizable for our users!
        </p>
        <p className="py-6">
          Mentors/Coaches can add assignments, set their deadlines and automatically notify their fellows. Consequently,
          Njano presents a portal to submit the deliverables to be reviewed and validated.
        </p>
        <p className="py-6">
          Since we recognize that incubators activity extends far beyond this, many more features are to come in the
          pipeline of Njano. Stay tuned for more!
        </p>
      </div>
    </div>
  </Layout>
);

export default ArticlePage;
